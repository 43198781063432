export const mintData = [
  {
    id: 0,
    title: "A Unique collection of NFTs at the centre of the HyHu Network",
  },
  {
    id: 1,
    title: "Collection will be in 4 drops.  Genesis Collection = 1,111 only",
  },
  {
    id: 2,
    title: "Utility Rich - Multiple Utilities linked to each Ecosystem",
  },
  {
    id: 3,
    title:
      "First of it's kind sustainable benefits to holders Utilities through continuous public funding",
  },
  {
    id: 4,
    title: "Created and Illustrated by Marvel Illustrator JL Giles",
  },
  {
    id: 5,
    title: "Extensive long term plans to create an incredible HyHu Network",
  },
  {
    id: 6,
    title: "Higher Tiers give additional benefits (number of cards held)",
  },
  {
    id: 7,
    title: "Sustainable, Adaptable and Innovative",
  },
];

export const mintTopList = [
  {
    id: 0,
    title: `Genesis Collection (1,111) - Sold out.`,
  },
  {
    id: 1,
    title: `Next drop coming soon.`,
  },
  
];
