import React from "react";
import { useEffect } from "react";
import ComingSoon from "../components/ComingSoon";
import { useNavigate } from "react-router-dom";

const Hyhu = () => {
  const navigate = useNavigate();
  useEffect(() => {
    const URL = "https://www.hyhuraffles.xyz/";
    window.open(URL, "_blank");
    navigate("*");
  }, []);

  return <ComingSoon />;
};

export default Hyhu;
