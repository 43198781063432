import { Button, InputNumber, Space } from "antd";
import React, { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import ABI from "../MintABI.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CONTRACT_ADDRESS = "0xb71AA1Fb2c49755A57F243077C5CFC9135a4BF8f";

const MintButton = ({ web3, account }) => {
  const meta = new web3.eth.Contract(ABI, CONTRACT_ADDRESS);
  const [mintQuantity, setMintQuantity] = useState(1);
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const [amountInETH, setAmountInETH] = useState(0);
  const [amountInWei, setAmountInWei] = useState(0);
  const [mintPrice, setMintPrice] = useState();
  const [isMinting, setIsMinting] = useState(false);
  const [hasMinted, setHasMinted] = useState(false);
  const [isPublic, setIsPublic] = useState(false);
  const [maxPerWallet, setMaxPerWallet] = useState(10);
  const [totalSupply, setTotalSupply] = useState(1111 - 200);
  const [txURL, setTxURL] = useState("");

  useEffect(() => {
    const initContract = async () => {
      const price = await meta.methods.MINT_PRICE().call();
      const isPublicMint = await meta.methods.publicSaleStarted().call();
      const supply = await meta.methods.totalSupply().call();

      setIsPublic(isPublicMint);
      setMintPrice(price);
      setTotalSupply(1111 - supply);
    };

    initContract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unitPrice = mintPrice;
    setAmountInWei(unitPrice * mintQuantity);
    if (unitPrice && amountInWei) {
      setAmountInETH(web3.utils.fromWei(`${amountInWei}`));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mintPrice, mintQuantity, amountInWei]);

  async function mint() {
    if (isPublic) {
      try {
        setIsMinting(true);
        await meta.methods
          .mint(mintQuantity)
          .send({ from: account, amount: mintQuantity, value: amountInWei })
          .then(receipt => {
            setIsMinting(false);
            setHasMinted(true);
            toast.dismiss();
            toast.success(
              `View your transaction here: https://etherscan.io/tx/${receipt.transactionHash} `
            );
          });
      } catch (err) {
        setIsMinting(false);
        toast.dismiss();
        toast.error(JSON.stringify(err));
      }
    }
  }

  return (
    <div>
      {!hasMinted ? (
        <div>
          {/* ONLY SHOW MINT UI IF PROOF EXISTS OR MINT IS PUBLIC */}
          {isPublic ? (
            <div>
              <div>
                <Space>
                  <InputNumber
                    size="large"
                    min={1}
                    max={maxPerWallet}
                    value={mintQuantity}
                    onChange={setMintQuantity}
                    autoFocus={true}
                  />
                  <Button
                    style={{ backgroundColor: "#5F2CA0" }}
                    type="primary"
                    size="large"
                    onClick={mint}
                  >
                    Mint
                  </Button>
                </Space>
              </div>
              <div>
                <Space>
                  <div
                    style={
                      isTabletOrMobile ? styles.mobileChance : styles.chance
                    }
                  >
                    {!isMinting ? (
                      <span>Total: {amountInETH}ETH</span>
                    ) : (
                      <div style={{ minWidth: "300px" }}>
                        {/* TX PENDING MESSAGE */}
                        Transaction pending...
                      </div>
                    )}
                  </div>
                </Space>
              </div>
            </div>
          ) : (
            <div
              style={{
                ...styles.chance,
                marginTop: "24px",
                fontSize: "20px",
                fontWeight: "bold",
              }}
            >
              Mint is not yet live.
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            ...styles.chance,
            marginTop: "24px",
            fontSize: "24px",
            fontWeight: "bold",
          }}
        >
          {/* TX SUCCESS MESSAGE */}
          You've Minted Successfully! <br />
          View your transaction <a href={txURL}>here.</a>
        </div>
      )}
      <ToastContainer
        autoClose={20000}
        theme="dark"
        dark="true"
        position="bottom-right"
      />
    </div>
  );
};

export default MintButton;

const styles = {
  chance: {
    color: "#A74B74",
    fontSize: 16,
    fontWeight: "500",
    fontFamily: "Poppins",
    backgroundColor: "#210F17",
    width: "80%",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    borderRadius: 6.5,
    marginBottom: 15,
    marginTop: 15,
  },
  mobileChance: {
    color: "#A74B74",
    fontSize: 16,
    fontWeight: "500",
    fontFamily: "Poppins",
    backgroundColor: "#210F17",
    width: "95%",
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 10,
    borderRadius: 6.5,
    marginTop: 20,
    marginBottom: 20,
  },
};
