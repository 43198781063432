import React from "react";
import MintButton from "../components/MintButton";

const goerliProvider =
  "https://eth-goerli.g.alchemy.com/v2/Hesbq0qdmfVcW0FwjwHaLjJ4vaIw8fjP";

const mainNetProvider =
  "https://eth-mainnet.g.alchemy.com/v2/8E0eVMxjjoaVDFQHNKjoOBHRUWwgwJoO";

const web3 = window.AlchemyWeb3.createAlchemyWeb3(mainNetProvider);

class ConnectWallet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      account: false,
    };
  }

  async connect() {
    try {
      await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const accts = await web3.eth.getAccounts();
      this.setState({ account: accts[0] });
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    let button;
    if (!this.state.account) {
      button = (
        <div onClick={this.connect.bind(this)} className="mint-now">
          Connect Wallet
        </div>
      );
    } else {
      button = <MintButton web3={web3} account={this.state.account} />;
    }
    return <div>{button}</div>;
  }
}

export default ConnectWallet;
